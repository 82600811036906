/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { computed, type MaybeRef } from 'vue'
import type { Route } from '~/src/Application/Shared/Router/RouterInterface'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import useEngagementNavigation from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'

export default function useGeneralLedgerNavigation(
  organisation: MaybeRef<Organisation>,
  engagement: MaybeRef<Engagement>,
  phase: MaybeRef<Phase>,
) {
  const { navigateTo } = useNavigation()
  const { navigateToEngagementLocalePath, getEngagementLocalePath } = useEngagementNavigation(organisation, engagement, phase)

  const generalLedgerIndexLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-Index'),
  )
  const generalLedgerOverviewLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-Overview'),
  )
  const generalLedgerMasterDataChartsOfAccountsLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-masterData-ChartsOfAccounts'),
  )
  const generalLedgerGlValidationLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-GLValidation'),
  )
  const generalLedgerJournalEntriesLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-JournalEntries'),
  )
  const generalLedgerEnteredDatesLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-timing-EnteredDates'),
  )
  const generalLedgerEnteredTimesLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-timing-EnteredTimes'),
  )
  const generalLedgerDaysLagLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-timing-DaysLag'),
  )
  const generalLedgerSourcesExplorerLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-explorer-Sources'),
  )
  const generalLedgerPreparersExplorerLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-explorer-Preparers'),
  )
  const generalLedgerBusinessUnitsExplorerLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-explorer-BusinessUnits'),
  )
  const generalLedgerOtherSideOfJournalEntriesLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-bookingPattern-OtherSideOfJournalEntries'),
  )
  const generalLedgerTwoWayCorrelationsLocalePath = computed(() =>
    getEngagementLocalePath('generalLedger-bookingPattern-TwoWayCorrelations'),
  )

  const navigateToGeneralLedgerIndexPage = async (): Promise<void> => {
    await navigateToEngagementLocalePath('generalLedger-Index')
  }

  const navigateToGeneralLedgerOverviewPage = async (): Promise<void> => {
    await navigateToEngagementLocalePath('generalLedger-Overview')
  }

  const navigateToGeneralLedgerBusinessUnitsExplorerPage = async (): Promise<void> => {
    await navigateTo(generalLedgerBusinessUnitsExplorerLocalePath.value)
  }

  const navigateToGeneralLedgerJournalEntriesPage = async (): Promise<void> => {
    await navigateTo(generalLedgerJournalEntriesLocalePath.value)
  }

  const navigateToGeneralLedgerPreparersExplorerPage = async (): Promise<void> => {
    await navigateTo(generalLedgerPreparersExplorerLocalePath.value)
  }

  const navigateToGeneralLedgerSourcesExplorerPage = async (): Promise<void> => {
    await navigateTo(generalLedgerSourcesExplorerLocalePath.value)
  }

  const navigateToGeneralLedgerOtherSideOfJournalEntryPage = async (
    query: Record<string, string | string[] | undefined> | undefined = undefined,
  ): Promise<void> => {
    await navigateTo({
      path: generalLedgerOtherSideOfJournalEntriesLocalePath.value,
      query,
    } as Route)
  }

  return {
    generalLedgerIndexLocalePath,
    generalLedgerOverviewLocalePath,
    generalLedgerMasterDataChartsOfAccountsLocalePath,
    generalLedgerGlValidationLocalePath,
    generalLedgerJournalEntriesLocalePath,
    generalLedgerEnteredDatesLocalePath,
    generalLedgerEnteredTimesLocalePath,
    generalLedgerDaysLagLocalePath,
    generalLedgerSourcesExplorerLocalePath,
    generalLedgerPreparersExplorerLocalePath,
    generalLedgerBusinessUnitsExplorerLocalePath,
    generalLedgerOtherSideOfJournalEntriesLocalePath,
    generalLedgerTwoWayCorrelationsLocalePath,
    navigateToGeneralLedgerIndexPage,
    navigateToGeneralLedgerOverviewPage,
    navigateToGeneralLedgerBusinessUnitsExplorerPage,
    navigateToGeneralLedgerPreparersExplorerPage,
    navigateToGeneralLedgerJournalEntriesPage,
    navigateToGeneralLedgerSourcesExplorerPage,
    navigateToGeneralLedgerOtherSideOfJournalEntryPage,
  }
}
